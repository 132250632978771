/* .DateInput_input {
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 8px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
} */

.DateRangePicker_picker__portal {
  z-index: 999 !important;
  pointer-events: all;
}
