table {
  border-spacing: 0;
  width: 100%;
  background-color: white;
}

th {
  margin: 0;
  padding: 0.5rem;
  background-color: midnightblue;
  color: white;
}

td {
  margin: 0;
  padding: 0.5rem;
  text-align: left;
}
:last-child {
  border-right: 0;
}

tr:last-child td {
  border-bottom: 0;
}
